.login-page{
  background: #F4E5E5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.container-style{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 88px 94px;
  background: #F7F6F5;
  border-radius: 20px;
  width: 30% !important;
  margin: 0 auto;
  position: relative;
}
.heading-style{
  font-family: 'Yeseva One';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 34px;
  text-align: center;
  letter-spacing: 1px;
  color: #4A4A4A;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.row-style{
  text-align: center;
}
.input-container{
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.email-input{
  box-sizing: border-box;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  padding: 4px 6px 4px 8px !important;
  gap: 10px;
  width: 358px !important;
  height: 44px;
  border: 2px solid #E2BEBD !important;
  border-radius: 4px !important;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  position: relative;
  font-size: 16px;
}

.password-input{
  margin-top: 24px;
}
.button-style{
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px !important;
  gap: 8px;
  width: 358px;
  height: 42px;
  border: 2px solid #F4E5E5 !important;
  border-radius: 4px !important;
  background: #F4E5E5 !important;
  border-radius: 30px !important;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  color: #9B9191 !important;
}
.button-style:hover{
  background: #C67D7B !important;
  color: #fff !important;
}
.eye-icon{
  position: absolute;
  right: 0;
  top: 0;
  /* background-color: white; */
  height: 42px;
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
 }
.eye-icon > img{
  width: 20px;
  height: 15px;
}
.form-group{
  position: relative;
}


/* // Extra small devices (portrait phones, less than 576px) */
@media (min-width: 0px) and (max-width: 250px) { 
  .container-style{
    padding: 20px 34px;
    width: 90% !important;
  }
  .heading-style{
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .email-input{
    width: 100% !important;
  }
  .button-style{
    width: 100% !important;
    align-self: center;
  }
 }
@media (min-width: 250px) and (max-width: 575.98px) { 
  .container-style{
    padding: 30px 44px;
    width: 80% !important;
  }
  .heading-style{
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .email-input{
    width: 100% !important;
  }
  .button-style{
    width: 100% !important;
    align-self: center;
  }
 }

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
  .container-style{
    padding: 40px 54px;
    width: 70% !important;
  }
  .heading-style{
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 20px;
  }
  .email-input{
    width: 100% !important;
  }
  .button-style{
    width: 100% !important;
    align-self: center;
  }
 }

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
  .container-style{
    padding: 50px 64px;
    width: 50% !important;
  }
  .heading-style{
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 20px;
  }
  .email-input{
    width: 100% !important;
  }
  .button-style{
    width: 100% !important;
    align-self: center;
  }
 }

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
  .container-style{
    padding: 60px 74px;
    width: 40% !important;
    margin: 0 auto;
  }
  .heading-style{
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 30px;
  }
  .email-input{
    width: 100% !important;
  }
  .button-style{
    width: 100% !important;
    align-self: center;
  }
  
 }

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1600px) { 
  .container-style{
    padding: 70px 84px;
    width: 35% !important;
    margin: 0 auto;
  }
 }