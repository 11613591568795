
.content{
  display: flex;
  padding-top: 40px;
  justify-content: center;
  height: 100vh;
}
.contentWraper{
  min-height: calc(100vh - 127px);
  width: 100%;
  padding-bottom:15px;
}
.user-heading{
  font-size: 24px !important;
  font-weight: 700 !important;
  color: #4A4A4A;
}
.top-button{
  background-color: #F6EEED !important;
  color: #9B9191 !important;
  padding:16px 30px !important;
  outline: transparent !important;
  border: transparent !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}

.toggle-buttons{
  background-color: #E2BEBD !important;
  border-radius: 4px !important;
  color: #4A4A4A !important;
  padding:16px 30px !important;
  outline: transparent !important;
  border: transparent !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}
.data-not-found{
  background: white;
  margin-top: 16px;
  padding: 10px;
  text-align: center;
  border-radius: 8px;
}
.loader-style{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.screen-container{
  padding: 0 5%
}
@media screen and (max-width:1200px) {
  .top-button{
    padding:16px 25px !important;
  }
  .toggle-buttons{
    padding:16px 25px !important;
  }}