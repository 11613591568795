.table{
    table-layout: fixed;
    border-collapse: collapse  !important;
    background-color: white  !important;
    border-radius: 8px  !important;
    margin: 0 !important;
   }
   .table thead{
    border: 3px solid #F4E5E5 ;
  }
   .table th{
    font-size: 14  !important;
    font-weight: 600  !important;
    text-align: start  !important;
    padding: 18px 25px  !important;
    background-color: #F4E5E5  !important;
    color: #4A4A4A  !important;
   }
   .table th:first-child{
   border-top-left-radius: 8px  !important;
      }
   .table th:last-child{
    border-top-right-radius: 8px  !important;
    }
    
    .table td:first-child{
    cursor: pointer;
      }
   .table td{
    font-size: 14  !important;
    font-weight: 400  !important;
    color: #808080  !important;
    padding: 25px  !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
   }
   .sort-tex-main{
    gap: 10px;
    display: flex;
    align-items: center;
   }
   .sort-tex-main img{
    height: 14px;
   }

   .delete-block-ions{
    gap: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
   }

   .delete-block-ions img{
    height: 20px;
    width: 20px;
    cursor: pointer;
   }

   .colapsed-row-main h6{ 
    font-size: 14;
    font-weight: 600;
    color: #4A4A4A ;
   }
   .colapsed-row-main ul{
    list-style-type: none;
    padding: 0 !important;
   }
   .colapsed-row-main ul li{
    display: flex;
    align-items: center;
    gap: 15px;
    }
  .colapsed-row-main ul li:before {
    content:"";
    height: 6px;
    width: 6px;
    max-width: 6px;
    min-width: 6px;
    background: #C67D7B;
    border-radius: 100px;
  }
  .user-type-before{
    background: #C67D7B;
    height: 6px;
    width: 6px;
    max-width: 6px;
    min-width: 6px;
    border-radius: 100px;
  }
  .user-type-main{
    display: flex;
    align-items: center;
    gap: 6px;
  }
  .table-responsive {
    margin-top: 16px;
    height: 440px;
    border-radius: 8px !important;
    overflow: auto !important;
  }
  .table-responsive::-webkit-scrollbar {
    height: 8px !important;
    width: 8px !important;
  }
  /* Track */
  .table-responsive::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #c67d7b;
    border-radius: 10px;
  }
  /* Handle */
  .table-responsive::-webkit-scrollbar-thumb {
    background-color:  #c67d7b;
    border-radius: 10px;
  }
.active-class{
border-left: 3px solid #C67D7B;
}
.pagination-style{
display: flex;
justify-content: center;
}
.pagination-style ul li{    
margin: 14px;
}
      @media screen and (max-width:1200px) {
        .top-button{
            padding:16px 25px !important;
           }
           .toggle-buttons{
            padding:16px 25px !important;
           }
           .table{
            width: 1200px !important;
           }
        
       }