.main-style{
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
  height: 63px;
  /* background-color: white; */
  border-radius: 8px;
}
.left-style{
  border: 1px solid rgb(234, 234, 234);
  height: 43px;
  width: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: white;
}
.center-style{
  border: 1px solid rgb(234, 234, 234);
  height: 43px;
  width: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: #808080  ;
  background-color: white;
}
.right-style{
  border: 1px solid rgb(234, 234, 234);
  height: 43px;
  width: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: white;
}

.active {  
  border: 1px solid #C67D7B;
  color: #C67D7B
}