.content{
    padding-top: 40px;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: #F7F6F5;
}
.contentWraper{
  min-height: calc(100vh - 127px);
  width: 100%;
  padding-bottom:15px;
}
.top-card-left{
  background: white;
  padding: 16px;
  border-radius: 8px;
}
.top-card-left label{
  color: #4A4A4A;
  font-size: 14px;
  font-weight:600;
}
.top-card-left span{
  color: #808080;
  font-size: 14px;
  margin-left: 4px;
}
.messageIcon{
 cursor:pointer;
}
.bottom-card-left{
  background: white;
  padding: 16px;
  border-radius: 8px;
  min-height: 332px;
  max-width: auto;
}
.bottom-card-left .message{
  font-size: 12px;
  font-weight: 400;
  color: #C67D7B;
}
.bottom-card-left .message-details{
  padding: 10px;
  border-left: 2px solid #C67D7B;
}
.bottom-card-left .message-text{
  font-size: 14px;
  font-weight: 400;
  color: #C67D7B;
  margin: 0;
}
.bottom-card-left .message-details-right{
  padding: 10px;
  border-right: 2px solid #000000;
  margin-bottom: 3px;
}
.bottom-card-left .message-text-right{
  font-size: 14px;
  font-weight: 400;
  color: #000000;

  margin: 0;
}
.msg-input input{
  border: none !important;
  outline: transparent;
  font-size: 16px !important;
  height: 44px !important;
  padding:8px 0 !important;
  color:#808080 !important;
  width: 100%;
}
.msg-input input:focus {
  border-color: transparent !important;
  box-shadow: none !important;
  color:#808080 !important;
}

.card-wraper{
height: 480px;
overflow: auto;
padding-right: 10px;
}
.card-wraper::-webkit-scrollbar {
  height: 8px !important;
  width: 8px !important;
}
/* Track */
.card-wraper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #c67d7b;
  border-radius: 10px;
}
/* Handle */
.card-wraper::-webkit-scrollbar-thumb {
  background-color: #c67d7b;
  border-radius: 10px;
}
.loader-style{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.data-not-found{
  background: white;
  margin-top: 16px;
  padding: 10px;
  text-align: center;
  border-radius: 8px;
}
.topCard-left-main{
  height:480px;
  overflow-y: scroll;
}


.topCard-left-main::-webkit-scrollbar {
  height: 8px !important;
  width: 8px !important;
}
/* Track */
.topCard-left-main::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #c67d7b;
  border-radius: 10px;
}
/* Handle */
.topCard-left-main::-webkit-scrollbar-thumb {
  background-color: #c67d7b;
  border-radius: 10px;
}
.replies-main{
  display: flex;
  justify-content: end;
}
.replies{
  margin-top: 20px;
}

.reply {
  margin-left: 25px;
  justify-content: flex-end;
  align-items: flex-end;
}

.repliedEmail{
color: #808080;
font-size: 14px;
}
.redpliedName{
  color:#c67d7b;
  font-size: 14px;
  font-weight: 500;
}
.repliedmessage{
  color: #808080;
  font-size: 14px;
}
.repliedsubject{
  color:#212529;
  font-size: 14px;
  font-weight: 500;
}