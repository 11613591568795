
    .navbar{
        background-color: #C67D7B !important;
        position: relative;
        min-height: 84px;
    }
    .navbar-toggler{
        position: absolute;
        top: 22px;
        right: 10px;
    }
    .nav-link{
        color: white !important;
        font-size: 16 !important;
        font-weight: 600 !important;
        padding: 10px 40px !important;
        border-bottom: 2px !important;
        border-bottom: 2px solid transparent !important;
    }
      .activeNavLink{
        border-bottom: 2px solid white !important;
        }
    .profile-section {
        display: flex;
        gap: 15px;
        align-items: center;
    }
    .profile-section img {
        height: 24px;
        width: 24px;
        border-radius: 100px;
    }
    .profile-name{
        font-size: 12;
        font-weight: 600;
        color: white;
    }
    .profile-status{
        font-size: 12;
        color: white;
    }
    .dot-main{
        position: relative;
    }
    .dot-icon{
    cursor: pointer;
    }
    .logout-tab{
    position: absolute;
    top: 60px;
    right: 0;
    background-color: white;
    width: 150px;
    padding: 15px;
    border-radius: 4px;
    }
    .logout-button{
    cursor: pointer;
    margin-bottom: 0;
    color: #C67D7B;
    }

@media screen and (min-width:1024px) {
.navbar-toggler{
    display: none !important;
    }
.navbar{
    height: 84px;
}
.navbar-nav{
    display:flex !important;
    flex-direction: row !important;
    gap: 20px;
}
.collapse{
    display: block !important;
}
.main-nav-list{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap:100px;
}

}