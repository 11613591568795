.card-top .title h6{ 
  color: #4A4A4A;
}

.notification-left-top-card{
    background: white;
    padding:0 16px;
    border-radius: 8px;
  }
  .bottom-notification-card{
    background: white;
    padding: 16px;
    border-radius: 8px;
  }
  .title-input {
    border: none !important;
    outline: transparent;
    font-size: 16px !important;
    height: 44px !important;
    padding:8px 0 !important;
    color:#808080 !important;
  }
  .title-input:focus {
    border-color: transparent !important;
    box-shadow: none !important;
    color:#808080 !important;
  }
  .notification-textarea{
    border: none !important;
    outline: transparent;
    font-size: 16px !important;
    height: 300px;
    width: 100%;
    color:#808080;
  }
  .notification-textarea:focus{
    border-color: transparent !important;    
  }
  .submitbtn-main{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 24px;
  }
  .submit-btn{
    background-color: #F4E5E5;
    color: #9B9191;
    padding: 12px 144px;
    border-radius: 20px;
    cursor: pointer;
  }
  .submit-btn:hover{
    background: #C67D7B !important;
    color: #fff !important;
  }

  .notification-card-wraper{
    height: 470px;
    overflow: auto;
    padding-right:  10px;
    }
    .notification-card-wraper::-webkit-scrollbar {
      height: 8px !important;
      width: 8px !important;
    }
    /* Track */
    .notification-card-wraper::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px  #c67d7b;
      border-radius: 10px;
    }
    /* Handle */
    .notification-card-wraper::-webkit-scrollbar-thumb {
      background-color:  #c67d7b;
      border-radius: 10px;
    }
    .loader-style{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .data-not-found{
      background: white;
      margin-top: 16px;
      padding: 10px;
      text-align: center;
      border-radius: 8px;
    }